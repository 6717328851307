import { SgConnectEnvironment } from 'services/SgConnect/SgConnectEnvironment';

export const SEARCH_DEBOUNCE_IN_MS = 300;
export const SEARCH_GLOBAL_TIMEOUT_IN_MS = 1000;

export enum Environment {
    Local = 'Local',
    Development = 'Development',
    Homologation = 'Homologation',
    Production = 'Production',
    Test = 'Test',
}

export const getEnvironment = (): Environment => {
    if ((window as any).__vitest_environment__ === 'happy-dom') {
        return Environment.Test;
    }

    const hostname = window.location.hostname;

    if (hostname === 'local.fr.world.socgen' || hostname === 'localhost') {
        return Environment.Local;
    }

    if (hostname === 'www-dev.sgmarkets.com') {
        return Environment.Development;
    }

    if (hostname === 'www-uat.sgmarkets.com') {
        return Environment.Homologation;
    }

    if (hostname === 'www.sgmarkets.com'
        || hostname === 'www-preview.sgmarkets.com') {
        return Environment.Production;
    }

    throw new Error(`Could not load configuration for: ${hostname}`);
};

export interface IConfiguration {
    sgConnect: {
        authorizationEndpoint: string;
        clientId: string;
        scope: string;
        level: string;
        redirect_uri: string;
    },
    baseApiUrls: {
        sgmSearch: string;
        notification: string;
        serviceBoard: string;
    };
    baseApiRewriteUrls: {
        origin: string,
        target: string,
    }[];
    sgConnectEnvironment: SgConnectEnvironment;
    piwik: {
        url: string;
        siteId: string;
    };
    infoUrl: string;
    insightUrl: string;
    insightPublicUrl: string;
    seeAllInIc: string;
    returnUrlDomainWhiteList: string[];
    sharedCdnUrl: string;
    nonSgmLinkReferentialUrl: string;
    logoutUrl: string;
    changePasswordUrl: string;
    manage3SKeyUrl: string;
    resetSecondFactorUrl: string;
    setupSecondFactorUrl: string;
    insightSubscriptionUrl: string;
    sharinboxPersoInfoUrl: string;
    personalInformationHelpSgGroup: string;
    personalInformationHelpClient: string;
    sgLinkDocumentation: string;
    sgMonitoringRumUrl: string;
};

const defaultConfiguration = {
    sgConnect: {
        authorizationEndpoint: 'https://sgconnect-hom.fr.world.socgen/sgconnect',
        clientId: '13ae8e63-0fb1-4236-b4d3-b73cce1b80a2',
        scope: 'openid profile mail api.serviceboard.v1 api.researchcontentdomain.v1 api.sg-markets-search.v1 api.researchsearchdomain.v1 api.idy-widgets.request-access api.sg-markets-notification.v1',
        level: 'L1',
        redirect_uri: '/myservices/',
    },
    sgConnectEnvironment: SgConnectEnvironment.Homologation,
    baseApiUrls: {
        sgmSearch: 'https://api-z-dev.sgmarkets.com/dev/services/sgm-search/private/v1',
        notification: 'https://sgmarkets-api-notification-dev.sgmpinsight.dev.euw.gbis.sg-azure.com',
        serviceBoard: 'https://sgmarkets-api-serviceboard-dev.sgmpinsight.dev.euw.gbis.sg-azure.com',
    },
    baseApiRewriteUrls: [
        {
            origin: 'https://api-s-uat.sgmarkets.com/services/insight/serviceboard/private/api/',
            target: 'https://api-s-uat.sgmarkets.com/services/insight/serviceboard/private/',
        },
        {
            origin: 'https://api-s-uat.sgmarkets.com/services/insight/notification/private/api/',
            target: 'https://api-s-uat.sgmarkets.com/services/insight/notification/private/',
        },
        {
            origin: 'https://api-s.sgmarkets.com/services/insight/serviceboard/private/api/',
            target: 'https://api-s.sgmarkets.com/services/insight/serviceboard/private/',
        },
        {
            origin: 'https://api-s.sgmarkets.com/services/insight/notification/private/api/',
            target: 'https://api-s.sgmarkets.com/services/insight/notification/private/',
        },
    ],
    infoUrl: 'https://info-dev.sgmarkets.com',
    insightUrl: 'https://insight-dev.sgmarkets.com',
    insightPublicUrl: 'https://insight-public-dev.sgmarkets.com',
    seeAllInIc: 'https://ic.sgmarkets.com/v2/searchresults/contacts?term={0}',
    returnUrlDomainWhiteList: [
        'sgmarkets.com',
        'sgmarkets-next.com',
        'socgen',
    ],
    sharedCdnUrl: 'https://shared-uat.fr.world.socgen',
    piwik: {
        url: 'https://sg-analytics-uat.fr.world.socgen/piwik',
        siteId: '877539670',
    },
    nonSgmLinkReferentialUrl: 'https://digital-uat.fr.world.socgen/sgmarkets-integration/#/LinkReferential/Form',
    logoutUrl: 'https://sgconnect-hom.fr.world.socgen/#/logout',
    changePasswordUrl: 'https://sgconnect-hom.fr.world.socgen/#/change-password',
    manage3SKeyUrl: 'https://globalcash-uat.sgmarkets.com/getstarted/#/enlistment',
    resetSecondFactorUrl: 'https://sgconnect-hom.fr.world.socgen/#/reset-2fa',
    setupSecondFactorUrl: 'https://sgconnect-hom.fr.world.socgen/#/login?acr_values=L3',
    insightSubscriptionUrl: 'https://subscription-dev.sgmarkets.com',
    sharinboxPersoInfoUrl: 'https://sharinbox-uat.sgmarkets.com/personalInformation',
    personalInformationHelpSgGroup: 'top.sneoiaoe',
    personalInformationHelpClient: 'top.jzu7lzcn',
    sgLinkDocumentation: 'https://docs.sgmarkets.com/shared/1564d0f8c270ac97726a2b4deff166ce4a306628d6bd8a5884b268f1147cbc83',
    sgMonitoringRumUrl: 'https://insight-realm-apm-hom.fleet.uat.sgmonitoring.dev.euw.gbis.sg-azure.com',
} satisfies IConfiguration;

export const configurationPerEnvironment: Record<Environment, IConfiguration> = {
    [Environment.Local]: {
        ...defaultConfiguration,
        baseApiUrls: {
            ...defaultConfiguration.baseApiUrls,
            // notification: 'https://localhost:57542'
            // serviceBoard: 'https://localhost:5000',
        },
    },
    [Environment.Test]: {
        ...defaultConfiguration,
        baseApiUrls: {
            sgmSearch: 'https://localhost',
            notification: 'https://localhost',
            serviceBoard: 'https://localhost',
        },
    },
    [Environment.Development]: {
        ...defaultConfiguration,
    },
    [Environment.Homologation]: {
        ...defaultConfiguration,
        baseApiUrls: {
            sgmSearch: 'https://api-z-dev.sgmarkets.com/uat/services/sgm-search/private/v1',
            notification: 'https://api-s-uat.sgmarkets.com/services/insight/notification/private',
            serviceBoard: 'https://sgmarkets-api-serviceboard-uat.sgmpinsight.dev.euw.gbis.sg-azure.com',
        },
        infoUrl: 'https://info-uat.sgmarkets.com',
        insightUrl: 'https://insight-uat.sgmarkets.com',
        insightPublicUrl: 'https://insight-public-uat.sgmarkets.com',
        insightSubscriptionUrl: 'https://subscription-uat.sgmarkets.com',
        sharinboxPersoInfoUrl: 'https://sharinbox-uat.sgmarkets.com/personalInformation',
    },
    [Environment.Production]: {
        ...defaultConfiguration,
        sgConnect: {
            ...defaultConfiguration.sgConnect,
            authorizationEndpoint: 'https://sso.sgmarkets.com/sgconnect',
            clientId: '2bb641e6-2874-4684-ad2d-0fc97619e114',
        },
        baseApiUrls: {
            sgmSearch: 'https://api-z.sgmarkets.com/services/sgm-search/private/v1',
            notification: 'https://api-s.sgmarkets.com/services/insight/notification/private',
            serviceBoard: 'https://api-s.sgmarkets.com/services/insight/serviceboard/private',
        },
        infoUrl: 'https://info.sgmarkets.com',
        insightUrl: 'https://insight.sgmarkets.com',
        insightPublicUrl: 'https://insight-public.sgmarkets.com',
        sharedCdnUrl: 'https://shared.sgmarkets.com',
        piwik: {
            url: 'https://t-log.sgmarkets.com',
            siteId: '170',
        },
        nonSgmLinkReferentialUrl: 'https://digital.fr.world.socgen/sgmarkets-integration/#/LinkReferential/Form',
        logoutUrl: 'https://sso.sgmarkets.com/#/logout',
        changePasswordUrl: 'https://sso.sgmarkets.com/#/change-password',
        manage3SKeyUrl: 'https://globalcash.sgmarkets.com/getstarted/#/enlistment',
        resetSecondFactorUrl: 'https://sso.sgmarkets.com/#/reset-2fa',
        setupSecondFactorUrl: 'https://sso.sgmarkets.com/#/login?acr_values=L3',
        insightSubscriptionUrl: 'https://subscription.sgmarkets.com',
        sharinboxPersoInfoUrl: 'https://sharinbox.sgmarkets.com/personalInformation',
        personalInformationHelpSgGroup: 'top.ajvpf2kg',
        personalInformationHelpClient: 'top.njy83yid',
        sgMonitoringRumUrl: 'https://t-monitoring-rum.sgmarkets.com/rum/540a239031b64d7fbe34fe54fef041db',
    },
};

export const configuration = configurationPerEnvironment[getEnvironment()];
